<script lang="ts" setup>
const { t } = useT();

const { open } = useAppModals();

const { questData, currentTask, taskCompleted } = useQuestTask();
const { dayInfo, isFreeSpinQuest } = useFreeSpinQuest();

const fpIsActive = computed(() => isFreeSpinQuest.value && !dayInfo.value?.isCompleted);

const handleOpenQuest = () => {
	if (isFreeSpinQuest.value) {
		open("LazyOModalFreeSpin");
		return;
	}
	open("LazyOModalQuests");
};
</script>

<template>
	<div class="wrapper">
		<NuxtImg class="image" src="/nuxt-img/quest/main-banner-quest.png" alt="quest" width="228" height="228" />

		<div class="content">
			<AText variant="toledo" :modifiers="['semibold']">
				<span>{{ t("Daily Missions") }}</span>
			</AText>
			<AText variant="taipei" :modifiers="['bold', 'uppercase']" class="text-tlalnepantla label">
				<span>{{ !!questData?.data?.dayInfo ? currentTask?.title : t("Coming soon") }}</span>
			</AText>
			<AButton
				v-if="!taskCompleted || fpIsActive"
				variant="primary"
				class="btn"
				size="lg"
				@click="open('LazyOModalRacesGames')"
			>
				<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">
					{{ t("Go!") }}
				</AText>
			</AButton>
			<AText v-else :modifiers="['bold']">
				{{ t("Mission completed ✅") }}
			</AText>
			<AText
				v-if="!!questData?.data?.dayInfo"
				as="div"
				variant="tempe"
				:modifiers="['bold', 'underline']"
				class="text-сirebon quest-link"
				@click="handleOpenQuest"
			>
				<span>{{ t("More info") }}</span>
			</AText>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.wrapper {
	width: 439px;
	height: 228px;
	border-radius: 16px;
	background: var(--gosaba);
	display: flex;
	justify-content: space-between;
	padding: 0 16px 20px 0;

	@include media-breakpoint-down(lg) {
		width: 100%;
	}

	@include media-breakpoint-down(md) {
		padding: 16px;
		justify-content: flex-end;
		height: 250px;
		position: relative;
		overflow: hidden;
	}
}

.image {
	@include media-breakpoint-down(md) {
		position: absolute;
		left: -60px;
	}
}

.content {
	padding-top: 24px;
	width: 175px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;

	@include media-breakpoint-down(md) {
		padding-top: 0;
	}
}

.label {
	text-shadow: 0px 0px 6px var(--carapicuiba);
}

.btn {
	min-height: 40px;
	width: 100%;
	margin-top: 16px;
}

.quest-link {
	margin: 6px auto 0;

	&:hover {
		cursor: pointer;
		text-decoration: none;
	}
}
</style>
